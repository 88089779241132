import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { AuthenticationService } from './services';
import { User } from './models';
import { Constants } from './utils/constants';
import * as $ from 'jquery';

@Component({
    selector: 'body',
	template: '<router-outlet></router-outlet>',
	styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
    @ViewChild('modalCreateTicket', { static: false }) public modalCreateTicket: ModalDirective;
    currentUser: User;
    timeout = false;
    constructor(
		private router: Router,
        private authenticationService: AuthenticationService,
        private constants: Constants,
    ) {
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    }

    ngOnInit() {
      	this.router.events.subscribe((evt) => {
			if (!(evt instanceof NavigationEnd)) {
				return;
			}
			
			window.scrollTo(0, 0);
		});
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }
}