import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { UserService, AlertService } from '../../../services';
import * as $ from 'jquery';
import { Constants } from '../../../utils/constants';
import { Title } from '@angular/platform-browser';
import { HelperService } from "../../../services/"

@Component({
    selector: 'app-auth',
    templateUrl: './forget-login-info.component.html',
})
export class ForgetLoginInfoComponent implements OnInit {    

    constructor(
        private titleService: Title,
        private formBuilder: FormBuilder,
        private userService: UserService,
        private alertService: AlertService,
        private helperService : HelperService, 
        private constants: Constants) { this.titleService.setTitle('Forget Password - HELP DESK SYSTEM'); }

    forgetInfoVal = 'forgotPassword';
    frmRequestResetPassword : FormGroup;
    submitted = false;
    loading = false;
    sentRequestSuccess = false;
    invalidToken;

    ngOnInit() {
        this.invalidToken = this.helperService.invalidToken;
        this.buildForm();
    }

    buildForm() {
        this.frmRequestResetPassword = this.formBuilder.group({
            'username' : [''],
            //'email' : ['']
        });
        this.setFormValidator();
    }

    get form() {
        return this.frmRequestResetPassword.controls;
    }

    // Check Form Validation
    setFormValidator() {

        /*if (this.forgetInfoVal == 'forgotPassword') {
            this.frmRequestResetPassword.controls['username'].setValidators([Validators.required]);
            this.frmRequestResetPassword.controls['email'].clearValidators();

        } else if (this.forgetInfoVal == 'forgotUserName') {
            this.frmRequestResetPassword.controls['email'].setValidators([Validators.required]);
            this.frmRequestResetPassword.controls['username'].clearValidators();
        }
        this.frmRequestResetPassword.controls['username'].updateValueAndValidity();
        this.frmRequestResetPassword.controls['email'].updateValueAndValidity();*/

        if (this.forgetInfoVal == 'forgotPassword') {
            this.frmRequestResetPassword.controls['username'].setValidators([Validators.required]);
        }

        this.frmRequestResetPassword.controls['username'].updateValueAndValidity();
    }

    checkForgetInfo(event:any) {

        let infoVal = $(event.target).val();
        if (infoVal != null) {
            this.forgetInfoVal = infoVal;
        }
        //if (this.forgetInfoVal == 'forgotPassword') {
        //    this.form.email.setValue("");
        //}
        //if (this.forgetInfoVal == 'forgotUserName') {
        //    this.form.username.setValue("");
        //}

        this.setFormValidator();
        this.submitted = false;
    }

    hideInvalidTokenAlert() {
        this.helperService.invalidToken = false;
    }

    closeErrorMessage() {
        this.helperService.invalidToken = false;
        $('.close-alert').hide();       
    }

    requestChangePassword() {
        this.submitted = true;
        if (this.frmRequestResetPassword.invalid) {
            return;
        }

        this.loading = true;
        let params = {
            username: this.form.username.value ? this.form.username.value : null,
            //email: this.form.email.value ? this.form.email.value  : null,
        }

        this.userService.requestChangePassword(params)
            .subscribe(
                response => {
                    this.submitted = false;
                    this.sentRequestSuccess = response['status'] || false;
                    if (response['status'] == false) {
                        let message = response['message'];
                        this.alertService.error(message, true);
                        this.submitted = true;
                        this.loading = false;
                    }
                },
                error => {
                    console.log(error);
                    this.loading = false;
                }
            )
    }

    public findInvalidControls() {
        const invalid = [];
        const controls = this.frmRequestResetPassword.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(name);
            }
        }
    }
}
