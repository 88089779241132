import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertService } from '../../services/alert.service';
import { AlertComponent } from './alert.component';

@NgModule({
    imports: [
        CommonModule
    ],
    providers: [AlertService],
	declarations: [AlertComponent],
	exports: [AlertComponent]
})
export class AlertModule { }