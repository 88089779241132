import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../services';
import { User } from '../../models';

@Component({
    selector: 'app-dashboard',
    templateUrl: 'autologin.component.html'
})
export class AutoLoginComponent implements OnInit {
    adminReturnUrl: string;
    userReturnUrl: string;
    currentUser =  <User>{};

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService) {
        this.currentUser = this.authenticationService.currentUserValue;
        localStorage.setItem('latestSidebar', 'user-portal');
    }

    ngOnInit() {
        this.authenticationService.logout();
        // Get return url from route parameters or default to '/'
        this.adminReturnUrl = this.route.snapshot.queryParams['returnUrl'] || '/user/portal';
        this.userReturnUrl = '/user/portal';        
        let token = this.route.snapshot.paramMap.get('token');
        this.authenticationService.autologin(token)
            .pipe(first())
            .subscribe(
                data => {
                    if (this.authenticationService.isLoggedIn) {
                        this.currentUser = this.authenticationService.currentUserValue;
                        let returnUrl = this.currentUser.group_role.length == 0 ? this.userReturnUrl : this.adminReturnUrl; 
                        this.router.navigate([returnUrl]);
                    }                                        
                },
                error => {
                    this.router.navigateByUrl('/login');
                }
            );
    }

}