import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../utils/constants';
import { NotificationScheme } from '../models/NotificationScheme';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class NotificationSchemeService {
    notificationSchemes: NotificationScheme[];
    notificationType: any;
    events: any;
    notificationTypes: any;
    event: any;
    
    constructor(private http: HttpClient, private constants: Constants) {}

    // Get list of project based on pagination
    getNotificationSchemeFilterList(params): Observable<NotificationScheme[]> {
        return this.http
            .post(`${this.constants.URL_API}/filter-notification-scheme`, params)
            .pipe(
                map(response => {
                    this.notificationSchemes = response['data'];
                    return this.notificationSchemes;
                })
            );
    }

    // Create a new project
    create(notificationScheme) {
        return this.http.post(this.constants.URL_API + '/notification-scheme', notificationScheme);
    }

    // Update project
    update(notificationScheme, id) {
        return this.http.post(this.constants.URL_API + '/update-notification-scheme/' + id, notificationScheme);
    }

    // Update project
    delete(id) {
        return this.http.delete(this.constants.URL_API + '/notification-scheme/' + id);
    }

    // Get project details information
    getNotificationSchemeById(id: number) {
        return this.http.get(this.constants.URL_API + '/notification-scheme/' + id)
            .pipe(
            map(response => {
                this.notificationSchemes = response['data'];
                return this.notificationSchemes;
            })
        );
    }

    // Get project details information
    getEventAndNotificationTypeById(id: number) {
        return this.http.get(this.constants.URL_API + '/event-notification-type/' + id)
            .pipe(
            map(response => {
                this.notificationSchemes = response['data'];
                return this.notificationSchemes;
            })
        );
    }

    // Get event and notification type detail
    getEventNotificationDetailById(params, id) {
        return this.http.post(this.constants.URL_API + '/detail-event-notification-type/' + id, {
                event_id: params.event_id,
                notification_type_id: params.notification_type_id 
            })
            .pipe(
            map(response => {
                this.notificationSchemes = response['data'];
                return this.notificationSchemes;
            })
        );
    }

    // Delete notification type
    deleteteNotificationType(params, id) {
        return this.http.post(this.constants.URL_API + '/delete-event-notification-type/' + id, {
                event_id: params.event_id,
                notification_type_id: params.notification_type_id 
            })
            .pipe(
            map(response => {
                this.notificationType = response;
                return this.notificationType;
            })
        );
    }

    // Get all events
    getAllEvents() {
        return this.http.get(this.constants.URL_API + '/event')
            .pipe(
            map(response => {
                this.notificationTypes = response['data'];
                return this.notificationTypes;
            })
        );
    }

    // Get all notification types
    getAllNotificationTypes() {
        return this.http.get(this.constants.URL_API + '/notification-type')
            .pipe(
            map(response => {
                this.events = response['data'];
                return this.events;
            })
        );
    }

    // Get event detail
    getEventDetail(event_id) {
        return this.http.get(this.constants.URL_API + '/event/' + event_id)
            .pipe(
            map(response => {
                this.event = response['data'];
                return this.event;
            })
        );
    }

    // Add notification type
    addNotificationType(params, id) {
        return this.http.post(this.constants.URL_API + '/add-event-notification-type/' + id, {
                event_id: params.event_id,
                notification_type_id: params.notification_type_id 
            })
            .pipe(
            map(response => {
                this.notificationType = response;
                return this.notificationType;
            })
        );
    }
}
