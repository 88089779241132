import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { Constants } from '../../utils/constants';
import { Title } from '@angular/platform-browser';
import { AlertService, UserService, AuthenticationService, DivisionService } from '../../services';

@Component({
	selector: 'app-auth',
	templateUrl: 'register.component.html'
})
export class RegisterComponent implements OnInit {
	frmAccount: FormGroup;
	loading = false;
	submitted = false;
	passwordMatched = false;
	checkPass = true;
	divisions: any;
	returnUrl: string;

	constructor(
		private titleService: Title,
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private authenticationService: AuthenticationService,
		private userService : UserService,
		private alertService: AlertService,
		private divisionService: DivisionService,
		private constants: Constants,
		private location: Location
	) {
		this.titleService.setTitle('Register - HELP DESK SYSTEM');

		// Redirect to dashboard if already logged in
		if (this.authenticationService.currentUserValue) {
			this.router.navigateByUrl('/dashboard');
		}
	}

	ngOnInit() {
		this.buildForm();
		this.getDivisions();
		// Get return url from route parameters or default to '/'
		this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
	}

	buildForm() {
		this.frmAccount = this.formBuilder.group({
			username: ['', Validators.required],			
			email: ['', Validators.required],
			password: ['', Validators.required],
			password_confirmation : ['', Validators.required],
			realname: [''],
			first_name: ['', Validators.required],
			middle_initial: [''],
			last_name: ['', Validators.required],
			title: [''],
			division: [''],
			supername: [''],
			superemail: ['']
		});
	}

	// Convenience getter for easy access to form fields
	get form() { return this.frmAccount.controls; }

	// Get all divisions
	getDivisions() {
		this.divisionService.getDivisions().subscribe((divisions) => {
			this.divisions = divisions;
		});
	}

	register() {
		this.submitted = true;
		// Stop here if form is invalid
		let invalid = false;
		if (this.frmAccount.invalid) {
			invalid = true;
		}

		if (this.form.password.value === this.form.password_confirmation.value) {
			this.passwordMatched = true;
		}

		if (this.passwordMatched == false) { // Confirm password does not match the password
			invalid = true;
		}

		let confirmedPass = this.form.password_confirmation.errors ? false : true;
		this.checkPass = confirmedPass && this.passwordMatched;

		if (invalid) {
			return;
		}

		this.loading = true;
		let realname = this.form.first_name.value;

		if (this.form.middle_initial.value) {
			realname = realname +' '+ this.form.middle_initial.value;
		}

		realname = realname + ' ' + this.form.last_name.value;

		let params = {
			first_name : this.form.first_name.value,
			middle_initial : this.form.middle_initial.value ,
			last_name : this.form.last_name.value,
			realname : realname,
			password : this.form.password.value,
			email : this.form.email.value,
			username: this.form.username.value,
			title: this.form.title.value,
			division_id: this.form.division.value,
			supervisor_name: this.form.supername.value,
			supervisor_email: this.form.superemail.value,
			send_notification_email: 1
		};

		//Send request to register new user
		this.userService.register(params).subscribe(
			response => {
				this.alertService.success(response['message'], true);
				setTimeout(() => {  
					this.router.navigate([this.returnUrl]);
				}, 1000);
			},
			error => {
				// let message = (typeof error.username[0] !== 'undefined')? error.username[0]: error.email[0];
				// this.alertService.error(message, true);
			}
		)
	}

	cancel() {
		this.location.back();
	}
}