import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { Constants } from '../utils/constants';
import { RequestType, RequestTypeGroup } from '../models';

@Injectable({
	providedIn: 'root'
})
export class RequestTypeService {
	group: RequestTypeGroup;
	requestType: RequestType;
	
	constructor(private http: HttpClient, 
				private constants: Constants) { }

	// Get list of request type groups by project ID
	requestTypeGroupByProjectId(id) {
		return this.http.get(this.constants.URL_API + '/rtgs-by-project/' + id)
			.pipe(map((response) => {
				return response['data'];
			},
			error => {
				console.log(error);
			})
		);
	}

	getTotalRequestOfGroup(groupId) {
		return this.http.get(this.constants.URL_API + '/total-request/' + groupId)
			.pipe(map((response) => {
				return response['data'];
			},
			error => {
				console.log(error);
			})
		);
	}

	getTotalGroupOfRequest(id) {
		return this.http.get(this.constants.URL_API + '/total-group-of-request/' + id)
			.pipe(map((response) => {
				return response['data'];
			},
			error => {
				console.log(error);
			})
		);
	}

	searchRequestType(params) {
		return this.http.post(this.constants.URL_API + '/search-request-type', params)
			.pipe(map((response) => {
				return response['data'];
			},
			error => {
				console.log(error);
			})
		);
	}
	
	// Get list of request types
	getRequestType() {
		return this.http.get(this.constants.URL_API + '/request-types')
			.pipe(map((response) => {
				return response['data'];
			},
			error => {
				console.log(error);
			})
		);
	}

	// Get request type by group
	getRequestTypeByGroup (params) {
		return this.http.post(this.constants.URL_API + '/request-types-by-group', params)
			.pipe(map((response) => {
				return response['data'];
			},
			error => {
				console.log(error);
			})
		);
	}

	// Get hidden request types
	getHiddenRequest(params) {
		return this.http.post(this.constants.URL_API + '/hidden-request-types', params)
			.pipe(map((response) => {
				return response['data']['requestTypesList'];
			},
			error => {
				console.log(error);
			})
		);
	}

	// Get group of a request type ID
	getRequestGroupByRequestType(id) {
		return this.http.get(this.constants.URL_API +'/rtgs-by-request-type/'+ id)
		.pipe(map((response) => {
				return response['data'];
			},
			error => {
				console.log(error);
			})
		);
	}

	// Get exists request types from a group
	getExistsRequest(params) {
		return this.http.post(this.constants.URL_API +'/available-request-types', params)
		.pipe(map((response) => {
				return response['data'];
			},
			error => {
				console.log(error);
			})
		);
	}

	//get request type by project id and issue type id
	getRequestTypeByProjectAndIssueType(params) {
		return this.http.post(this.constants.URL_API +'/list-request-types-from-project-issue-type', params)
		.pipe(map((response) => {
				return response['data']['requestTypesList'];
			},
			error => {
				console.log(error);
			})
		);
	}

	// Update request type group
	updateGroup(params) {
		return this.http.put(this.constants.URL_API + '/request-type-groups/' + params.id, params);
	}

	// Add new request type group
	createGroup(group) {
		return this.http.post(this.constants.URL_API + '/request-type-groups', group);
	}

	// Delete request type group
	deleteGroup(id) {
		return this.http.delete(this.constants.URL_API + '/request-type-groups/' + id);
	}

	// Create new request type
	createNewType(data:any) {
		return this.http.post(this.constants.URL_API+ '/request-types', data);
	}

	// Update a request type
	updateType(data:any) {
		return this.http.post(this.constants.URL_API+ '/request-type/update', data);
	}

	// Delete a request type
	deleteType(params) {
		return this.http.post(this.constants.URL_API + '/request-type/delete', params);
	}

	// Edit groups for a request
	updateGroupForRequestType(params) {
		return this.http.post(this.constants.URL_API + '/update-rtgs-by-request-type', params);
	}

	// Update Default Assignee to a request type
	updateDefaultAssignee(params) {
		return this.http.post(this.constants.URL_API + '/request-type/update-default-assignee', params);
	}

	// Get default assignee of a request type
	getDefaultAssigneeByRequestType(params) {
		return this.http.post(this.constants.URL_API + '/request-type/get-default-assignee', params);
	}

	// Get default assignee of a request type group 
	getDefaultAssigneeOfRTG(params) {
		return this.http.post(this.constants.URL_API + '/rtg-default-assignee', params)
	}
}
