
interface NavAttributes {
	[propName: string]: any;
}
interface NavWrapper {
	attributes: NavAttributes;
	element: string;
}
interface NavBadge {
	text: string;
	variant: string;
}
interface NavLabel {
	class: string;
}
interface SubNavLink {
	class: string;
}

export interface NavData {
	name?: string;
	url?: string;
	icon?: string;
	badge?: NavBadge;
	title?: boolean;
	children?: NavData[];
	variant?: string;
	attributes?: NavAttributes;
	divider?: boolean;
	class?: string;
	label?: NavLabel;
	wrapper?: NavWrapper;
	subLink?: SubNavLink;
}

export const navAdminItems: NavData[] = [
	{
		title: true,
		name: 'Administration'
	},
	{
		name: 'Projects',
		url: '/project-management',
		icon: 'fa fa-product-hunt'
	},
	{
		name: 'Issues',
		url: '/issue-management',
		icon: 'icon-puzzle',
		children: [
			{
				name: 'Issue Types',
				url: '/issue-management/issue-types',
				icon: 'fa fa-dot-circle-o'
			},
			{
				name: 'Issue Type Schemes',
				url: '/issue-management/issue-type-schemes',
				icon: 'fa fa-dot-circle-o'
            },
            {
				name: 'Screen Schemes',
				url: '/issue-management/screen-schemes',
				icon: 'fa fa-dot-circle-o'
			},
			// {
			// 	name: 'Workflows',
			// 	url: '/issue-management/workflows',
			// 	icon: 'fa fa-dot-circle-o'
			// },
			// {
			// 	name: 'Custom Fields',
			// 	url: '/issue-management/custom-fields',
			// 	icon: 'fa fa-dot-circle-o'
			// },
			// {
			// 	name: 'Statuses',
			// 	url: '/issue-management/statuses',
			// 	icon: 'fa fa-dot-circle-o'
			// },
			{
				name: 'Notification Schemes',
				url: '/issue-management/notification-schemes',
				icon: 'fa fa-dot-circle-o'
			}
		]
	},
	{
		name: 'Users',
		url: '/user-management',
		icon: 'fa fa-users'
	},
	// {
	// 	name: 'System',
	// 	url: '/system-management',
	// 	icon: 'fa fa-cog',
	// 	children: [
	// 		{
	// 			name: 'Roles',
	// 			url: '/system-management/roles',
	// 			icon: 'fa fa-dot-circle-o'
	// 		},
	// 		{
	// 			name: 'Permissions',
	// 			url: '/system-management/permissions',
	// 			icon: 'fa fa-dot-circle-o'
	// 		},
	// 		{
	// 			name: 'Look and Feel',
	// 			url: '/system-management/look-and-feel',
	// 			icon: 'fa fa-dot-circle-o'
	// 		},
	// 		{
	// 			name: 'Shared Filters',
	// 			url: '/system-management/shared-filters',
	// 			icon: 'fa fa-dot-circle-o'
	// 		},
	// 	]
	// },
];

export const navPSItems: NavData[] = [
	{
		title: true,
		name: 'Project Settings'
	},
	{
		name: 'Project Name',
		url:  '/project-management/project-settings/details',
		icon: 'fa fa-product-hunt',
		class: 'project-settings custom-project-name'
	},
	{
		name: 'Details',
		url:  '/project-management/project-settings/details',
		icon: 'icon-list icons',
		class: 'project-settings custom-project-details'
	},
	{
		name: 'Delete Project',
		url:  '/project-management/project-settings/delete',
		icon: 'fa fa-remove',
		class: 'project-settings custom-project-delete'
	},
	// {
	// 	title: true,
	// 	name: 'Queues'
	// },
	// {
	// 	name: 'Queues',
	// 	url:  'queues',
	// 	icon: 'cui-layers icons',
	// 	class: 'custom-project-queues'
	// },
	{
		title: true,
		name: 'Issues'
	},
	{
		name: 'Request Types',
		url: '/project-management/project-settings/request-types',
		icon: 'fa fa-question-circle',
		class: 'project-settings request-types'
	},
	// {
	// 	name: 'Issue Types',
	// 	url: '/project-settings/issue-types',
	// 	icon: 'fa fa-houzz'
	// },
	// {
	// 	name: 'Workflows',
	// 	url: '/project-settings/workflows',
	// 	icon: 'fa fa-share-alt'
	// },
	// {
	// 	name: 'Custom Fields',
	// 	url: '/project-settings/custom-fields',
	// 	icon: 'cui-puzzle icons'
	// },
	{
		name: 'Settings',
		icon: 'nav-icon fa fa-cog',
		children: [
			{
				name: 'Users and Roles',
				url: '/project-management/project-settings/users-and-roles',
				icon: 'icon-user icons',
				class: 'project-settings users-roles'
			},
			// {
			// 	name: 'Permissions',
			// 	url: '/project-settings/permissions',
			// 	icon: 'fa fa-product-hunt'
			// },
			// {
			// 	name: 'Versions',
			// 	url: '/project-settings/versions',
			// 	icon: 'cui-graph icons'
			// },
			// {
			// 	name: 'Notifications',
			// 	url: '/project-settings/notifications',
			// 	icon: 'nav-icon icon-bell'
			// }
		]
	}
];

export const navDBItems: NavData[] = [
	{
		title: true,
		name: 'Dashboard'
	},
	{
		name: 'My View',
		url: '/dashboard',
		icon: 'icon-speedometer',
	},
	/*{
		name: 'Raise A Request',
		url: '#',
		icon: 'cui-note icons',
	}*/
];
export const navIssueItems: NavData[] = [
	{
	 	title: true,
	 	name: 'Help desk issues'
	},
	// {
	//  	name: 'Find filters',
	//  	url: '#',
	//  	icon: ''
	// },
	{
	 	name: 'My open issues',
	 	url: '/issues/my-open-issues',
	 	icon: ''
	},
	{
	 	name: 'Reported by me',
	 	url: '/issues/reported-by-me',
	 	icon: ''
	},
	{
	 	name: 'All issues',
	 	url: '/issues/all-issues',
	 	icon: ''
	},
	{
	 	name: 'Open issues',
	 	url: '/issues/open-issues',
	 	icon: ''
	},
	{
	 	name: 'Closed issues',
	 	url: '/issues/closed-issues',
	 	icon: ''
	}
];
