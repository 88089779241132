import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { UserService, AlertService, AuthenticationService } from '../../../services';
import { Router, ActivatedRoute } from "@angular/router";
import { Title } from '@angular/platform-browser';
import { HelperService } from "../../../services/"

@Component({
    selector: 'app-auth',
    templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit {

    constructor(
        private titleService: Title,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router : Router,
        private userService : UserService,
        private authService : AuthenticationService,
        private helperService : HelperService, 
        private alertService : AlertService) { this.titleService.setTitle('Reset Password - HELP DESK SYSTEM'); }

    frmResetPwd : FormGroup;
    submitted = false;
    loading = false;
    passwordMatched = false;
    infoFilled = false;
    token;
    email;

    ngOnInit() {
        this.getParams();
        this.buildForm();
    }

    buildForm() {
        this.frmResetPwd = this.formBuilder.group({
            'password' : ['', Validators.required],
            'password_confirmation' : ['', Validators.required]
        });
    }

    get form() {
        return this.frmResetPwd.controls;
    }

    getParams() {
        this.route.params.subscribe(params => {
            this.email = params.email;
            this.token = params.token;

            let data = {
                email: this.email,
                token: this.token
            }
            this.authService.checkToken(data)
                .subscribe(response => {},
                     error => {
                        this.helperService.invalidToken = true;
                        this.router.navigate(['/password/email']);
                    }
                )
        });
    }

    onSubmit() {
        this.submitted = true;
        this.infoFilled = (this.form.password.value && this.form.password_confirmation.value );

        if (this.frmResetPwd.invalid) {
            return;
        }

        if (this.form.password.value === this.form.password_confirmation.value) {
            this.passwordMatched = true;
        }

        if (this.passwordMatched == false) { // Confirm password does not match the password
            return;
        }

        this.loading = true;
        let params = {
            email : this.email,
            token : this.token,
            password: this.form.password.value,
            password_confirmation : this.form.password_confirmation.value
        };

        // Call API to update password
        this.userService.resetPassword(params).subscribe(
            response => {
                this.submitted = false;                                
                this.alertService.success(response['message'], true);
                setTimeout(() => {  
                    this.router.navigate(['/login']);
                }, 1000);
            },
            error => {
                console.log(error);
                this.submitted = false;
            }
        )
    }
}
