import { Component, OnDestroy, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';

import { navAdminItems, navPSItems, navDBItems, navIssueItems } from './navigation';
import { User, Project } from '../../models';
import { AuthenticationService, HelperService, IssueService } from '../../services';
import { Constants } from '../../utils/constants';
import { trigger, transition, animate, style } from '@angular/animations'

@Component({
	selector: 'app-dashboard',
	templateUrl: './default-layout.component.html',
	styleUrls: ['./default-layout.component.scss'],
	animations: [
		trigger('slideInOut', [
			transition(':enter', [
				style({transform: 'translateX(-100%)'}),
				animate('200ms ease-in', style({transform: 'translateX(0%)'}))
			]),
			transition(':leave', [
				animate('200ms ease-in', style({transform: 'translateY(-100%)'}))
			])
		])
	]
})

export class DefaultLayoutComponent implements OnDestroy {
	public currentUser;	
	public navAdminItems = navAdminItems;
	public navPSItems = navPSItems;
	public navDBItems = navDBItems;
	public navIssueItems = navIssueItems;//sidebar on issue page
	public sidebarMinimized = true;
	private changes: MutationObserver;
	public element: HTMLElement;
		
	// Define dropdown variables
	currentRoute: string;
	status: { isOpen: boolean } = { isOpen: false };
	disabled: boolean = false;
	isDropup: boolean = true;
	autoClose: boolean = false;
	totalRequestOfCurUser : any;
	isUser: boolean = false;
	
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private constants: Constants,
		private authenticationService: AuthenticationService,
		private helperService: HelperService,
		private issueService: IssueService,
		@Inject(DOCUMENT) _document?: any,
	) {
		this.currentUser = this.authenticationService.currentUserValue;
		this.changes = new MutationObserver((mutations) => {
			this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
		});

		this.element = _document.body;
		this.changes.observe(<Element>this.element, {
			attributes: true,
			attributeFilter: ['class']
		}); 
	}

	ngOnInit() {
		// Update sidebar
		this.helperService.updateSidebar();
		// this.getTotalRequestOfUser();

		// Setting header
		this.settingHeaderByUserRole();
	}

	minimizeSibar() {
		if (this.sidebarMinimized) {
			// Show HELPDESK ISSUES links
			$('.nav-issue-items').removeClass('hide-issue-links');
			
			$('.sidebar-minimizer').css({'bottom': '54px'});
			$('.app-footer').css('margin-left', '200px');
			$('.app-body .main').css('margin-left', '200px');
			$('.main .container-fluid').css('padding', '15px 25px 0px 15px');
			$('.sidebar-minimizer').removeClass('minimum').addClass('maximum');

		} else {
			// Hide HELPDESK ISSUES links
			$('.nav-issue-items').addClass('hide-issue-links');

			$('.sidebar-minimizer').css({'bottom': '0'});
			$('.app-footer').css('margin-left', '0');
			$('.app-body .main').css('margin-left', '50px');
			$('.main .container-fluid').css('padding', '15px 25px 0px 15px');        		
			$('.sidebar-minimizer').removeClass('maximum').addClass('minimum');
		}
	}

	ngOnDestroy(): void {
		this.changes.disconnect();
	}

	isLoggedIn() { return this.authenticationService.isLoggedIn(); }

	logout() {
		this.authenticationService.logout();
		this.router.navigate(['/login']);
	}

	toggleDropdown($event: MouseEvent): void {
		$event.preventDefault();
		$event.stopPropagation();
		this.status.isOpen = !this.status.isOpen;
	}

	change(value: boolean): void {
		this.status.isOpen = value;
	}

	// Get total count of My Requests
	getTotalRequestOfUser() {
		this.issueService.getTotalRequestOfUser({'user_id': this.currentUser.id})
			.subscribe(response=>{
				this.helperService.setTotalRequest(response['totalRequest']);
				this.helperService.currentTotalRequest.subscribe(total=> this.totalRequestOfCurUser = total);
			})
	}

	// Setting header
	settingHeaderByUserRole() {
		this.isUser = this.helperService.checkUserRole();
		$('a.navbar-brand').click(function(e) {
			e.preventDefault();
			
			let sidebar = localStorage.getItem('latestSidebar');
			let firstLink = (sidebar == 'user-portal')? '/#/user/portal' : '/#/dashboard';
			let secondLink = this.isUser ? '/#/user/portal' : '/#/dashboard';
			let logoLink = (firstLink != secondLink)? firstLink: secondLink;
			let latest = (logoLink == 'user-portal')? 'user-portal' : 'dashboard';
			localStorage.setItem('latestSidebar', latest);

			$('a.navbar-brand').attr('href', logoLink);			
			window.location.href = logoLink;
		});
	}
	
	login() {
		this.authenticationService.updateLoginStatus(true);
		this.router.navigate(['/login']);
	}
}