import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Constants } from '../utils/constants';
import { User } from '../models';
import { Project } from '../models/Project';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserService {

	constructor(private http: HttpClient, private constants: Constants) { }

	users: User[];
	projects: Project[];
	result: any;

	// Register a new user
	register(params) {
		return this.http.post(this.constants.URL_API + '/register', params);
	}

	// Send request to reset the password
	requestChangePassword(params) {
		return this.http.post(this.constants.URL_API + '/password/request-change', params);
	}

	//Reset passord
	resetPassword(params) {
		return this.http.post(this.constants.URL_API + '/password/reset', params);
	}

	update(user: User) {
		return this.http.put(this.constants.URL_API + '/users/' + user.id, user);
	}

	delete(id) {
		return this.http.delete(this.constants.URL_API + '/users/' + id);
	}

	// Get list of users
	getUsersList(params): Observable<User[]> {
		return this.http.post(`${this.constants.URL_API}/filter-users`, params)
			.pipe(map((response) => {
				this.users = response['users'];
				return this.users;
			})
		);
	}

	getAllUsers() {
		return this.http.get(this.constants.URL_API + '/users')
		.pipe( map((response) => {
				//console.log(response['data']);
				return response['data']['users'];
			}) 
		);
	}

	getAssignableUsers() {
		return this.http.get(this.constants.URL_API + '/assignable-users')
		.pipe( map((response) => {
				return response['data'];
			}) 
		);
	}
	// Get user details information
	getUserById(id: number) {
		return this.http.get(this.constants.URL_API + '/users/' + id)
			.pipe(map((response) => {
				return response['data'];

			})
		);
	}

	// Get default account preferences of the user
	getDefaultPrefs() {
		return this.http.get(this.constants.URL_API + '/user/reset-account-prefs')
			.pipe(map((response) => {
				return response;
			})
		);
	}

	// Get current account preferences of the user
	getCurrentPrefs(user_id) {
		return this.http.get(this.constants.URL_API + '/user/account-prefs/' + user_id)
			.pipe(map((response) => {
				return response;
			})
		);
	}

	// Update user information
	// This for update user on User preferences page, with adding the avatar
	updateUser(user) {
		return this.http.post(this.constants.URL_API + '/user/update', user);
	}

	// Update user's preference
	updateUserPref(user) {
		return this.http.post(this.constants.URL_API + '/user/update-account-prefs', user);
	}

	//update user inline, allow to update access level/ status of user
	updateInline(params) {
		return this.http.post(this.constants.URL_API + '/user/update-inline', params);
	}

	// Update user's profile
	updateUserProfile(user) {
		return this.http.post(this.constants.URL_API + '/user/update-profile', user);
	}

	setPassword(user) {
		return this.http.post(this.constants.URL_API + '/user/set-password', user);
	}

	// Update project roles for the user
	updateProjectRoles(params) {
		return this.http.post(this.constants.URL_API + '/user/update-project-roles', params);
	}

	// Get list of assigned projects for the user
	getAssignedProjects(params) {
		return this.http.post(this.constants.URL_API + '/user/assigned-projects', params)
			.pipe(map((response) => {
				this.projects = response['data'];
				return this.projects;
			})
		);
	}

	// Get list of unassigned projects for the user
	getUnassignedProjects(params) {
		return this.http.post(this.constants.URL_API + '/user/unassigned-projects', params)
			.pipe(map((response) => {
				this.projects = response['data'];
				return this.projects;
			})
		);
    }
    
	// Get list of unassigned projects for the user
	getUsersByKeywordAndExcept(params) {
		return this.http.post(this.constants.URL_API + '/search-users', params)
			.pipe(map((response) => {
				this.users = response['data'];
				return this.users;
			})
		);
	}
	
	// Set Email Notification for user
	setEmailNotification(user) {
		return this.http.post(this.constants.URL_API + '/user/update-notify-me', user);
	}

	// Get All Active Users
	getActiveUsers() {
		return this.http.get(this.constants.URL_API + '/active-users')
			.pipe(map((response) => {
				return response['data']['users'];
			})
		);
	}
}