import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../utils/constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IssueType } from '../models/IssueType';
import { ScreenSchema } from '../models';

@Injectable({
    providedIn: 'root'
})
export class IssueTypeService {
    issueTypes: IssueType[];
    constructor(private http: HttpClient, private constants: Constants) {}

    // Get list of Issue Types
    getIssueType() {
        return this.http.get(this.constants.URL_API + '/issue-types')
            .pipe(map((response) => {
                return response['data'];
            },error=>{
                console.log(error);
            })
        );
    }

    // Đelete an issue type
    deleteIssueType(id) {
        return this.http.delete(this.constants.URL_API + '/issue-types/' + id);
    }

    // Get issue types by project
    getIssueTypesByProjectId(projectId) {
        return this.http.get(this.constants.URL_API + '/list-issue-types-by-project/'+projectId)
            .pipe(map((response) => {
                return response['data'];
            },error=>{
                console.log(error);
            })
        );
    }

    // Get total issue matching with current issue type
    getTotalMatchingIssues(issueTypeId) {
        return this.http.get(this.constants.URL_API + '/issue-types/total-matching-issues/' + issueTypeId)
            .pipe(map((response) => {
                return response['data'];
            }, error=>{
                console.log(error);
            })
        );
    }

    // Get list of Issue Type Schemas
    getIssueTypeShemas() {
        return this.http.get(this.constants.URL_API + '/issue-type-schemas')
            .pipe(map((response) => {
                return response['data'];
            },error=>{
                console.log(error);
            })
        );
    }

    // Get list of Issue Type Schemas with pagination
    getIssueTypeSchemasWithPagination(params) {
        return this.http.post(this.constants.URL_API + '/filter-issue-type-schemas', params)
            .pipe(map((response) => {
                return response['data']['issueTypeSchemasList'];
            },error=>{
                console.log(error);
            })
        );
    }

    // Get an Issue Type Schema by ID
    getIssueTypeSchemaById(id: number) {
        return this.http.get(this.constants.URL_API + '/issue-type-schemas/' + id)
            .pipe(map((response) => {
                return response['data']['issueTypeSchema'];
            })
        );
    }

    // Get list of Issue Types from current Issue Type Schema ID
    getIssueTypesOfCurrrentScheme(schemeId) {
        return this.http.get(this.constants.URL_API +'/issue-types-from-schema/'+schemeId)
        .pipe(map((response)=>{
            return response['data']['issueTypesList'];
        }));
    }

    // Get list of available Issue Types from current Issue Type Schema ID
    getAvailableIssueTypes(schemeId) {
        return this.http.get(this.constants.URL_API +'/available-issue-types-from-schema/'+schemeId)
        .pipe(map((response)=>{
            return response['data']['issueTypesList'];
        }));
    }
    getProjectsOfCurrentScheme(schemeId) {
        return this.http.get(this.constants.URL_API +'/list-of-projects-on-schema/'+schemeId)
        .pipe(map((response)=>{
            return response['data']['projectsList'];
        }));
    }

    // Create new Issue Type Schema
    createIssueTypeSchema(params) {
        return this.http.post(this.constants.URL_API + '/issue-type-schemas', params);
    }

    // Update an Issue Type Scheme
    updateIssueTypeScheme(params) {
        return this.http.put(this.constants.URL_API +'/issue-type-schemas/'+params.id, params);
    }

    // Associate an Issue Type Schema
    associateIssueTypeScheme(params) {
        return this.http.post(this.constants.URL_API +'/associate-issue-type-schema', params);
    }

    // Clone an Issue Type Schema
    cloneIssueTypeSchema(params) {
        return this.http.post(this.constants.URL_API +'/clone-issue-type-schema', params);
    }

    // Delete an Issue Type Schema
    deleteIssueTypeSchema(id) {
        return this.http.delete(this.constants.URL_API +'/issue-type-schemas/'+id);
    }

    // Get list of Issue Types based on pagination
    getIssueTypesFiltersList(params): Observable<IssueType[]> {
        return this.http.post(`${this.constants.URL_API}/filter-issue-types`, params).pipe(
            map(response => {
                this.issueTypes = response['data']['issueTypesList'];
                return this.issueTypes;
            })
        );
    }

    // Get an Issue Type details information
	getIssueTypeById(id: number) {
		return this.http.get(this.constants.URL_API + '/issue-types/' + id)
			.pipe(map((response) => {
				this.issueTypes = response['data'];
				return this.issueTypes;
			})
		);
	}

    // Create an Issue Type
    create(params) {
        return this.http.post(this.constants.URL_API + '/issue-types', params);
    }

    // Update an Issue Type
    update(params) {
        return this.http.post(this.constants.URL_API + '/issue-type/update', params);
    }

    // Get list Screen Schemas base on pagination
    getScreenSchemasWithPagination(params) {
        return this.http.post(this.constants.URL_API + '/filter-screen-schemas', params)
            .pipe(map((response) => {
                return response['data']['screenSchemasList'];
            }, error => {
                console.log(error);
            })
        );
    }

    // Get list Screen
    getScreenList() {
        return this.http.get(this.constants.URL_API + '/screens')
            .pipe(map((response) => {
                return response['data']['screensList'];
            }, error => {
                console.log(error);
            })
        );
    }

    // Create a Screen Schema
    createScreenSchema(params) {
        return this.http.post(this.constants.URL_API + '/screen-schemas ', params);
    }

    // Delete a Screen Schema
    deleteScreenSchema(id) {
        return this.http.delete(this.constants.URL_API + '/screen-schemas/' + id);
    }

    // Update a Screen Schema
    updateScreenSchema(params) {
        return this.http.put(this.constants.URL_API + '/screen-schemas/' + params.id, params);
    }

    // Clone a Screen Schema
    cloneScreenSchema(params) {
        return this.http.post(this.constants.URL_API + '/clone-screen-schema', params);
    }

    // Get a Screen Schema by ID
    getScreenSchemaById(id: number) {
        return this.http.get(this.constants.URL_API + '/screen-schemas/' + id)
            .pipe(map((response) => {
                return response['data']['screenSchema'];
            })
        );
    }

    // Get Issue Operation by Screen Schema ID
    getIssueOperationByScreenSchemaId(params) {
        return this.http.post(this.constants.URL_API + '/list-issue-operation-asssociated', params)
            .pipe(map((response) => {
                return response['data']['issueOperationsList'];
            }, error => {
                console.log(error);
            })
        );
    }

    // Get Issue Operation by Screen Schema ID
    getIssueOperationNotAssociated(params) {
        return this.http.post(this.constants.URL_API + '/list-issue-operation-not-asssociated', params)
            .pipe(map((response) => {
                return response['data']['issueOperationsList'];
            }, error => {
                console.log(error);
            })
        );
    }

    // Delete Associated Issue Operation
    deleteAssociatedIssueOperation(params) {
        return this.http.post(this.constants.URL_API + '/delete-issue-operation-screen', params);
    }

    // Associate Issue Operation to Screen
    asscociateIssueOperationToScreen(params) {
        return this.http.post(this.constants.URL_API + '/associate-issue-operation-screen', params);
    }

    // Update Issue Operation Screen
    updateAssociatedIssueOperation(params) {
        return this.http.post(this.constants.URL_API + '/update-issue-operation-screen', params);
    }

    // Get Issue Type Id
    getIssueTypeId(params) {
        return this.http.post(this.constants.URL_API + '/get-issue-type-id', params)
            .pipe(map((response) => {
                return response['data'];
            }, error => {
                console.log(error);
            })
        );
    }
}
