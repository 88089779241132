import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
	selector: 'app-500',
  	templateUrl: '500.component.html'
})
export class P500Component {

	constructor(private titleService: Title) {
		this.titleService.setTitle('500 Error - HELP DESK SYSTEM');
	}
}
