import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { WIPComponent } from './views/wip/wip.component';
//import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';
import { LoginComponent } from './views/login/login.component';
import { ForgetLoginInfoComponent } from './views/login/forget-login-info/forget-login-info.component';
import { ResetPasswordComponent } from './views/login/forget-login-info/reset-password.component';
import { AutoLoginComponent } from './views/autologin/autologin.component';
import { RegisterComponent } from './views/register/register.component';
import { ForbiddenComponent } from './views/forbidden/forbidden.component';
import { AuthGuard } from './guards';

export const routes: Routes = [
    { path: '404', component: P404Component, data: { title: '404 Error - Help Desk System' }},
    { path: '500', component: P500Component, data: { title: '500 Error - Help Desk System' }},
    { path: 'login', component: LoginComponent, data: { title: 'Log-in - Help Desk System' }},
    { path: 'password/email', component: ForgetLoginInfoComponent, data: { title: '' }},
    { path: 'password_resets/:email/:token', component: ResetPasswordComponent, data: { title: 'Reset password - Help Desk System' }},
    { path: 'autologin/:token', component: AutoLoginComponent, data: { title: 'Auto Login - Help Desk System' }},
    { path: 'register', component: RegisterComponent, data: { title: 'Register - Help Desk System' }},
    { path: 'unauthorized', component: ForbiddenComponent, data: { title: 'Unauthorized - Help Desk System' }},
    { path: '',
        component: DefaultLayoutComponent,
        canActivate: [AuthGuard],
        runGuardsAndResolvers: 'always',
        data: { title: 'Home' },
        children: [
            // {
            //     path: 'base',
            //     loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule)
            // },
            // {
            //     path: 'buttons',
            //     loadChildren: () => import('./views/buttons/buttons.module').then(m => m.ButtonsModule)
            // },
            // {
            //     path: 'charts',
            //     loadChildren: () => import('./views/chartjs/chartjs.module').then(m => m.ChartJSModule)
            // },
            {
                path: 'dashboard',
                loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
            },
            // {
            //     path: 'icons',
            //     loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
            // },
            {
                path: 'notifications',
                loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
            },
            // {
            //     path: 'widgets',
            //     loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
            // },
            {
                path: 'user-management',
                loadChildren: () => import('./views/modules/user-management/user-management.module').then(m => m.UserManagementModule)
            },
            {
                path: 'project-management',
                loadChildren: () => import('./views/modules/project-management/project-management.module').then(m => m.ProjectManagementModule)
            },
            {
                path: 'project-settings/request-types',
                redirectTo: 'project-management/request-types'
            },
            {
                path: 'issue-management',
                loadChildren: () => import('./views/modules/issue-management/issue-management.module').then(m => m.IssueManagementModule)
            },
            {
                path: 'issues',
                loadChildren: () => import('./views/modules/issues/issues.module').then(m => m.IssuesModule)
            },
            {
                path: 'my-requests',
                loadChildren: () => import('./views/modules/my-request/my-requests.module').then(m => m.MyRequestsModule)
            },
            {
                path: 'user/portal',
                loadChildren: () => import('./views/modules/user-portal/user-portal.module').then(m => m.UserPortalModule)
            },
            {
                path: 'page',
                loadChildren: () => import('./views/modules/page/page.module').then(m => m.PageModule)
            },
            {
                path: 'user/profile',
                loadChildren: () => import('./views/modules/user-profile/user-profile.module').then(m => m.UserProfileModule)
            },

        ]
    },
    { path: '**', component: WIPComponent }
];

@NgModule({
    imports: [ RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'}) ],
    exports: [ RouterModule ]
})

export class AppRoutingModule {}
