import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthenticationService } from '../../services';

@Component({
	selector: 'app-auth',
	templateUrl: 'forbidden.component.html'
})
export class ForbiddenComponent implements OnInit {
	public currentUser;
	public isUser: boolean = false;
	public homePageLink: String;

	constructor(private titleService: Title, 
				private authenticationService: AuthenticationService,) 
	{
		this.titleService.setTitle('Unauthorized - HELP DESK SYSTEM');
		this.currentUser= this.authenticationService.currentUserValue;
	}

	ngOnInit() {
		this.getUserRole();
	}

	getUserRole() {
		let role = '';

		if (this.currentUser.group_role.length > 0) {
			role = this.currentUser.group_role[0].name;
		}
		
		this.isUser = (role == 'customers' || role == '') ? true : false;
		this.homePageLink = this.isUser ? '/#/user/portal' : '/#/user/portal';
	}
}
