import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import * as $ from 'jquery';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class HelperService {

    private project = new BehaviorSubject(localStorage.key);
    currentProjectId = this.project.asObservable();

    private issue = new BehaviorSubject<any>({});
	currentIssue = this.issue.asObservable();

	private totalRequest = new BehaviorSubject(localStorage.key);
	currentTotalRequest = this.totalRequest.asObservable();

	private statuses = new BehaviorSubject<any>({});
	conserveStatues = this.statuses.asObservable();

	invalidToken: boolean;
	currentUser;
	
    constructor(
		private router: Router,
		private authenticationService: AuthenticationService,) {}

	// Get Host URL
	getCurrentHost() {
		let host = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port;
		return host;
	}

	// Get current url from browser
    getCurrentRoute() {

        // Get Host URL
        let host = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port;

        // Get current address url
		let browserAddressUrl = window.location.href;

        // Get current route
		let currentRoute = browserAddressUrl.substring(browserAddressUrl.length-(browserAddressUrl.length - host.length -2), browserAddressUrl.length);

		return currentRoute;
	}

    // Update Sidebar
    updateSidebar() {

        // Get the current site URL
		let currentUrl = this.router.url;
		$('.app-body > .main').removeClass('custom-main');

		if (currentUrl.includes('/dashboard')) {
			localStorage.setItem('latestSidebar', 'dashboard');

			if (this.checkUserRole()) this.router.navigate(['/unauthorized']);
			this.showDashboard();
			
		} else if (currentUrl.includes('/projects')) {
			localStorage.setItem('latestSidebar', 'projects');

			if (this.checkUserRole()) this.router.navigate(['/unauthorized']);
			this.showProjects();
			
		} else if (currentUrl.includes('/issues') || currentUrl.includes('/issues/*')) {
			localStorage.setItem('latestSidebar', 'issues');
			if (this.checkUserRole()) this.router.navigate(['/unauthorized']);
			this.showIssues();
			
		} else if (currentUrl.includes('/project-management/project-settings')) {
			localStorage.setItem('latestSidebar', 'project-settings');

			if (this.checkUserRole()) this.router.navigate(['/unauthorized']);
			this.showProjectSettings();
						
		} else if (currentUrl.includes('/project-management') ||
			currentUrl.includes('/issue-management') ||
			currentUrl.includes('/user-management') ||
			currentUrl.includes('/system-management')) {
			localStorage.setItem('latestSidebar', 'management');

			if (this.checkUserRole()) this.router.navigate(['/unauthorized']);
			this.showAdministration();			

        } else if (currentUrl.includes('/my-requests') || 
        	currentUrl.includes('/user/portal')) {
        	localStorage.setItem('latestSidebar', 'user-portal');
			this.showUserPortal();
			
		} else if (currentUrl.includes('/about-us') || 
			currentUrl.includes('/contact-us') ||
			currentUrl.includes('/faqs') ||
			currentUrl.includes('/user/profile')) {
			let currentUser = this.authenticationService.currentUserValue;
			if (this.checkUserRole()) {
				localStorage.setItem('latestSidebar', 'user-portal');
				this.showUserPortal();		
			} else {				
				this.showCorrectSidebar();
			}

		} else if (currentUrl.includes('/')) {
			let currentUser = this.authenticationService.currentUserValue;
			if (this.checkUserRole()) {
				localStorage.setItem('latestSidebar', 'user-portal');
				this.router.navigateByUrl('/user/portal');
				this.showUserPortal();	
			} else {
				localStorage.setItem('latestSidebar', 'dashboard');
				this.router.navigateByUrl('/dashboard');
				this.showDashboard();			
			}
		}

		this.setSideNavLinkHover();
		this.setPaddingWhenChangingSideBar();
	}

	showCorrectSidebar() {
		let sidebar = localStorage.getItem('latestSidebar');
		let adminRole = $('.main > .container-fluid').hasClass('management-section');
		if (sidebar == 'dashboard' || sidebar == 'user-portal') {
			$('.static-page-container .content').css('margin-left', '90px');						
			$('#user-profile .content-row').css('margin-left', '76px');
		} else {
			$('.static-page-container .content').css('margin-left', '0');						
			$('#user-profile .content-row').css('margin-left', '0');
		}

		switch (sidebar) {
			case 'dashboard':
				this.showDashboard();
				break;

			case 'user-portal':
				this.showUserPortal();
				break;

			case 'management':
				this.showAdministration();
				break;

			case 'project-settings':
				this.showProjectSettings();
				break;

			case 'issues':
				this.showIssues();
				break;

			case 'projects':
				this.showProjects();
				break;

			default:
				// code...
				break;
		}
	}

	// Show dashboard sidebar
	showDashboard() {
        $('.side-bar').hide();
        $('.app-body .main').css('margin-left', '0');
        $('.app-footer').css('margin-left', '0');
		$('.nav-admin-items').hide();
		$('.nav-ps-items').hide();
		$('.nav-db-items').hide();
		$('.nav-issue-items').hide();
		$('.management-panel .my-requests').hide();
		$('.management-panel .dashboard').hide();
		$('.management-panel .admin-search').show();
		$('.management-panel .admin-settings').show();
	}

    // Show my request sidebar
	showUserPortal() {
        $('.side-bar').hide();
        $('.app-body .main').css('margin-left', '0');
        $('.app-footer').css('margin-left', '0');
		$('.nav-admin-items').hide();
		$('.nav-ps-items').hide();
		$('.nav-db-items').hide();
		$('.nav-issue-items').hide();
		$('.management-panel .my-requests').show();
		$('.management-panel .dashboard').show();
		$('.management-panel .admin-search').hide();
		$('.management-panel .admin-settings').hide();
	}

	// Show projects sidebar
	showProjects() {
        $('.side-bar').show();
        $('.app-body .main').css('margin-left', '200px');
        $('.app-footer').css('margin-left', '200px');
		$('.nav-admin-items').hide();
		$('.nav-ps-items').hide();
		$('.nav-dashboard-items').show();
		$('.nav-issue-items').hide();
		$('.management-panel .my-requests').hide();
		$('.management-panel .dashboard').hide();
	}

	// Show issues sidebar
	showIssues() {
        $('.side-bar').show();
        $('.app-body .main').css('margin-left', '200px');
        $('.app-footer').css('margin-left', '200px');
		$('.nav-admin-items').hide();
		$('.nav-ps-items').hide();
		$('.nav-issue-items').show();
		$('.nav-db-items').hide();
		$('.management-panel .my-requests').hide();
		$('.management-panel .dashboard').hide();
	}

	// Show administration sidebar
	showAdministration() {
        $('.side-bar').show();
        $('.app-body .main').css('margin-left', '200px');
        $('.app-footer').css('margin-left', '200px');
		$('.nav-admin-items').show();
		$('.nav-ps-items').hide();
		$('.nav-db-items').hide();
		$('.nav-issue-items').hide();
		$('.management-panel .my-requests').hide();
		$('.management-panel .dashboard').hide();
	}

	// Show project settings sidebar
	showProjectSettings() {
        $('.side-bar').show();
        $('.app-body .main').css('margin-left', '200px');
        $('.app-footer').css('margin-left', '200px');
		$('.nav-admin-items').hide();
		$('.nav-ps-items').show();
		$('.nav-db-items').hide();
		$('.nav-issue-items').hide();
		$('.management-panel .my-requests').hide();
		$('.management-panel .dashboard').hide();
	}

	// Save object into session
	setSession(key, obj) {
		localStorage.setItem(key, JSON.stringify(obj));
	}

	// Get session's value by key
	getSession(key) {
		const objVal = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : null;
		return objVal;
	}

	// Remove object out of session
	removeSession(key) {
		localStorage.removeItem(key);
	}

	// Filter item by key
	filterItemByKey(items, id) {
		if (items) {
            return items.filter(elm => elm.id === id);
		}
		return null;
    }

    // Filter item by name
    filterItemByName(items, name) {
		if (items) {
            return items.filter(elm => elm.link_name === name);
		}
		return null;
    }

    // Get array of items without belong to another array
	filterItemWithoutKey(items, ids) {
		if (items) {
			return items = items.filter(function(item) {
				return !ids.includes(item.id);
			});
		}
		return null;
    }

    // Set project id
    setProject(data: any) {
        this.project.next(data);
        //localStorage.key = data;
    }

	filterFieldByNameAlias(fields, alias ) {
		if (fields) {
			return fields.filter(elm => elm.control_name === alias);
		}
		return null;
	}

	//filter the objects base on the key's value
	filterObjByKey(objects, key, value) {
		//console.log('object:', objects, 'key ', key, 'value:', value);
		if (objects.length && key && value) {
			//console.log('true');
			return objects.filter(elm => elm[key] === value);
		};
		return null;
	}

	setIssue(data: any) {
		this.issue.next(data);
	}

	getIssue() {
		return this.issue.asObservable();
	}

	setConserveStatus(statuses:any) {
		this.statuses.next(statuses);
	}

	checkUserRole() {
		this.currentUser = this.authenticationService.currentUserValue;
		let role = '';
		
		if (this.currentUser.group_role.length > 0) {
			role = this.currentUser.group_role[0].name;
		}
		
		let userRole = (role == 'customers' || role == '') ? true : false;
		return userRole;
	}

	// Set total request number
	setTotalRequest(data: any) {
		this.totalRequest.next(data);
		localStorage.key = data;
	}

	//Set sidebar nav link when hovering
	setSideNavLinkHover() {
		$('.nav-item .nav-link').mouseover(function() {
			$(this).addClass('on-hover');
		});

		$('.nav-item .nav-link').mouseout(function() {
			$(this).removeClass('on-hover');
		});
	}

	//Set padding left when changing side bar
	setPaddingWhenChangingSideBar() {
		if ($('.sidebar-minimizer').hasClass('minimum')) {
			$('.app-body .main').css('margin-left', '50px');
        	$('.app-footer').css('margin-left', '50px');

        	if (this.router.url.includes('/dashboard') || this.router.url.includes('/user-portal')) {
        		$('.app-body .main').css('margin-left', '0');
			}
		}
	}
}
