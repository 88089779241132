import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../utils/constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Role } from '../models';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

	roles: Role[];

  	constructor(
		private http:HttpClient,
        private constants: Constants) { }
	
	getRoles(): Observable<Role[]> {
      	return this.http.get(`${this.constants.URL_API}/roles`)
    	 	.pipe(map((response) => {			  
				this.roles = response['data']['roles'];
				return this.roles;
        	})
      	);
    }

    getUserGroupRoles() {
        return this.http.get(this.constants.URL_API + '/group-roles')
        .pipe( map((response) => {
            return response['data']['roles'];
            })
        );
    }
}
