import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertService } from '../services';
import { AuthenticationService } from '../services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
                   
            if ((err.status === 401 && err.statusText.toUpperCase() === 'UNAUTHORIZED') || 
                (err.status === 0 && err.statusText.toUpperCase() === 'UNKNOWN ERROR')) { // For Login page               
                // Auto logout if 401 response returned from api
                this.authenticationService.logout();
                this.router.navigateByUrl('/login');                
            }

            if (err.status === 404 && err.statusText.toUpperCase() === 'NOT FOUND') { // For page not found               
                // Redirect to page not found                 
                this.router.navigateByUrl('/404');
            }
            
            const error = err.error.message || err.statusText;
            return throwError(error);
        }))
    }
}