import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
	selector: 'app-wip',
	templateUrl: 'wip.component.html'
})

export class WIPComponent {

	constructor(private titleService: Title) {
		this.titleService.setTitle('Work In Progress - HELP DESK SYSTEM');
	}
}
