import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../utils/constants';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DivisionService {
    
    constructor(private http: HttpClient, private constants: Constants) {}

    // Get list of divisions
    getDivisions() {
        return this.http.get(this.constants.URL_API + '/divisions')
            .pipe(map((response) => {
                return response['data']['divisions'];
            })
        );
    } 
}
