import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../utils/constants';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class FaqService {
    faqs: any;
    
    constructor(private http: HttpClient, private constants: Constants) {}

    // Get list of faqs
    getFaqs(params) {
        return this.http.post(this.constants.URL_API + '/faqs/search', params)
            .pipe(
            map(response => {
                this.faqs = response['data'];
                return this.faqs;
            })
        );
    }
    
}
