import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { JwtInterceptor, ErrorInterceptor } from './helpers';
import { AuthGuard } from './guards/';
import { AuthenticationService, HelperService } from './services';
import { Constants } from './utils/constants';
import { UserIdleModule } from 'angular-user-idle';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

// Import containers
import { DefaultLayoutComponent } from './containers';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AppComponent } from './app.component';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { WIPComponent } from './views/wip/wip.component';
import { LoginComponent } from './views/login/login.component';
import { ForgetLoginInfoComponent } from './views/login/forget-login-info/forget-login-info.component';
import { ResetPasswordComponent } from './views/login/forget-login-info/reset-password.component';
import { AutoLoginComponent } from './views/autologin/autologin.component';
import { RegisterComponent } from './views/register/register.component';
import { CreateTicketComponent } from './views/modules/issues/create-ticket/create-ticket.component';
import { ForbiddenComponent } from './views/forbidden/forbidden.component';
import { NgxSelectModule } from 'ngx-select-ex';
import { AlertModule } from './components/alert/alert.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app.routing';

const APP_CONTAINERS = [
    DefaultLayoutComponent
];

import {
    AppAsideModule,
    AppBreadcrumbModule,
    AppHeaderModule,
    AppFooterModule,
    AppSidebarModule,
} from '@coreui/angular';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { DpDatePickerModule } from 'ng2-date-picker';
import { AngularEditorModule } from '@kolkov/angular-editor';

@NgModule({
    imports: [
        BrowserAnimationsModule,
       	BrowserModule,
        AppRoutingModule,
        AppAsideModule,
        AppBreadcrumbModule.forRoot(),
        AppFooterModule,
        AppHeaderModule,
        AppSidebarModule,
        AlertModule,
        PerfectScrollbarModule,
        BsDropdownModule.forRoot(),
        TabsModule.forRoot(),
		PaginationModule.forRoot(),
        ChartsModule,
		// ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'always'}),
		ReactiveFormsModule,
        FormsModule,
		HttpClientModule,
        ModalModule.forRoot(),
        NgxDropzoneModule,
        DpDatePickerModule,
        AngularEditorModule,
        NgxSelectModule,
        UserIdleModule.forRoot({idle: Constants.DEFAULT_SESSION_LOGIN_TIMEOUT, timeout: 300, ping: 120}),
		TypeaheadModule.forRoot()
    ],
    declarations: [
        AppComponent,
        ...APP_CONTAINERS,
        P404Component,
		P500Component,
		WIPComponent,
        LoginComponent,
        AutoLoginComponent,
		RegisterComponent,
		CreateTicketComponent,
		ForgetLoginInfoComponent,
        ResetPasswordComponent,
        ForbiddenComponent
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        AuthGuard,
		AuthenticationService,
		HelperService,
        Constants
    ],
	bootstrap: [ AppComponent ]
})

export class AppModule { }
