import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { IssueType, Project, Issue, User, RequestType, Resolution, Priority } from '../../../../models';
import { IssueTypeService, ProjectService, UserService, IssueService, RequestTypeService, AlertService, AuthenticationService } from '../../../../services';
import { Constants } from '../../../../utils/constants';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/public_api';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import * as $ from 'jquery';

@Component({
    selector: 'app-create-ticket',
    templateUrl: './create-ticket.component.html',
    styleUrls: ['./create-ticket.component.scss']
})
export class CreateTicketComponent implements OnInit {
    @Output() refresh: EventEmitter<any> = new EventEmitter<any>();
    
    @ViewChild('modalCreateTicket', { static: false }) public modalCreateTicket: ModalDirective;
    issueTypes: IssueType;
    users: User[];
    reporters: User[];
    projects: Project[];
    selectedProjectId : any;
    projectLeadId: any;
    defaultAssigneeId: any;
    selectedIssueTypeId: any;
    selectedRequestTypeId: any;
    requestTypes: RequestType[];
    resolutions: Resolution[];
    priorities: Priority[];
    confFields:any;
    confFieldId:any;
    selectedConfFields: any;
    reporter_id;
    assignee_id;
    curUser;
    resolution_id;
    priority_id;
    linkIssueIds: any = [];
    linkTypes;
    submitted = false;
    loading = false;
    excludeConfFields = [
        'Subject',
        'Linked Issues'
    ];
    user;
    issueLinks;
    timeout = null;
    frmTicket: FormGroup;
    files: File[] = [];
    issues: Issue[];
    // Config Editor
    editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: 'auto',
        minHeight: '150px',
        maxHeight: 'auto',
        width: 'auto',
        minWidth: '0',
        translate: 'yes',
        enableToolbar: true,
        showToolbar: true,
        placeholder: 'Enter text here...',
        defaultParagraphSeparator: '',
        defaultFontName: '',
        defaultFontSize: '',
        fonts: [
            {class: 'arial', name: 'Arial'},
            {class: 'times-new-roman', name: 'Times New Roman'},
            {class: 'calibri', name: 'Calibri'},
            {class: 'comic-sans-ms', name: 'Comic Sans MS'}
        ],
        customClasses: [
            {
                name: 'quote',
                class: 'quote',
            },
            {
                name: 'redText',
                class: 'redText'
            },
            {
                name: 'titleText',
                class: 'titleText',
                tag: 'h1',
            },
        ],

        // UploadUrl: 'v1/image',
        sanitize: true,
        toolbarPosition: 'top',
    };

    isCustom:boolean = true;

    constructor(
        private issueService: IssueService,
        private issueTypeService: IssueTypeService,
        private projectService: ProjectService,
        private userService: UserService,
        private requestTypeService: RequestTypeService,
        private alertService: AlertService,
        private authService: AuthenticationService,
        private formBuilder: FormBuilder,
        private constants: Constants) {}

    ngOnInit() {
        this.getProjects();
        this.getUsers();
        this.getResolutions();
        this.getPriorities();
        this.getIssues();
        this.listRequestTypes();
        this.curUser = this.authService.currentUserValue;
        this.buildForm();
        this.getIssueLinks();
        this.showFields();
    }

    setDefaultProject(projects:any) {
        if (projects.length > 0) {
            this.select.setValue(projects[0].id);
            this.selectedProjectId = projects[0].id;
            this.getIssueTypesByProjectId(projects[0].id);
            // this.getProjectLead(projects[0].id);
        }
    }

    setDefaultIssueType(issueTypes:any) {
        if (issueTypes) {
            this.selectedIssueTypeId = issueTypes[0].id;
            this.selectIssueTypeBox.setValue(issueTypes[0].id);
        }
        if (this.selectedProjectId && this.selectedIssueTypeId) {
            let params = {
                project_id: this.selectedProjectId,
                issue_type_id: this.selectedIssueTypeId
            };

            this.requestTypeService.getRequestTypeByProjectAndIssueType(params)
                .subscribe((requestTypes)=>{
                    this.requestTypes = requestTypes;
                    this.setDefaultRequestType(this.requestTypes)
                }
            )
            this.getConfFieldList();
        }
    }

    setDefaultRequestType(requestTypes:any) {
        if (requestTypes) {
            this.selectedRequestTypeId = requestTypes[0].id;
            this.selectRequestTypeBox.setValue(requestTypes[0].id);

            // Get Default Assignee for the ticket
            this.onChangeRequestType(this.selectedRequestTypeId);
        }
    }

    buildForm() {
        this.frmTicket = this.formBuilder.group({
            reporter_id:[''],
            project_id:['', Validators.required],
            issue_type_id:['', Validators.required],
            request_type_id:['', Validators.required],
            assignee_id:[''],
            subject: ['', Validators.required],
            description:['']
        });
    }

    get form() {
        return this.frmTicket.controls;
    }

    get select() {
        return this.form.project_id as FormControl;
    }

    get selectIssueTypeBox() {
        return this.form.issue_type_id as FormControl;
    }

    get selectRequestTypeBox() {
        return this.form.request_type_id as FormControl;
    }

    showPopup() {
        this.modalCreateTicket.show();
        if (!this.selectedProjectId) {
            this.setDefaultProject(this.projects);
        }
    }

    hidePopup() {
        this.modalCreateTicket.hide();
    }

    // Get all users on system
    getUsers() {
        this.userService.getActiveUsers()
        .subscribe((users)=>{
            this.users = users;
            if (!this.reporters) {
                this.reporters = this.users;
            }
        })
    }

    getIssueTypesByProjectId(projectId: any) {
        this.issueTypeService.getIssueTypesByProjectId(projectId)
            .subscribe((data:IssueType)=>{
                this.issueTypes = data['issueTypesList'];
                this.setDefaultIssueType(this.issueTypes);
                this.getConfFieldList();
        })
    }

    getIssues() {
        this.issueService.getIssues()
            .subscribe((issues) => {
                this.issues = issues;
                if (this.issues) {
                    this.issues.forEach (function(elm) {
                        let key: any = elm['id'];
                        if (elm['project'] && elm['project']['key']) {
                            key = elm['project']['key'] + '-' + key;
                        }
                        
                        elm['link_key'] = key;
                        elm['avatar'] = elm['issue_type']['avatar'];
                    });
                }
            });
    }

    listRequestTypes() {
        this.requestTypeService.getRequestType().subscribe((types:RequestType[]) => {
            this.requestTypes = types['requestTypeList'] ? types['requestTypeList'] : [];
        });
    }

    getProjects() {
        return this.projectService.getProjectList()
        .subscribe((data)=>{
            this.projects = data;
            this.setDefaultProject(data);                                                                                                                                                                                               
        })
    }

    selectIssueType(issueTypeId:any) {
        this.selectedIssueTypeId = issueTypeId;
        
        // Reset value on request type field
        this.frmTicket.controls.request_type_id.setValue(null);

        // Check filed is null 
        if (this.submitted == true && this.frmTicket.controls.request_type_id.value == null) {
            $('.validate-request-type').addClass('no-request-type');
        }

        if (this.selectedProjectId && this.selectedIssueTypeId) {
            let params = {
                project_id: this.selectedProjectId,
                issue_type_id: this.selectedIssueTypeId
            };

            this.requestTypeService.getRequestTypeByProjectAndIssueType(params)
                .subscribe((requestType)=>{
                    this.requestTypes = requestType;
                }
            )
            this.getConfFieldList();
        }
    }

    getConfFieldList() {
        if (this.selectedProjectId && this.selectedIssueTypeId) {
            let params = {
                project_id: this.selectedProjectId,
                issue_type_id: this.selectedIssueTypeId,
                issue_operation_type: "create"
            };
            
            this.issueService.getConfFields(params)
            .subscribe( data => {            
                this.confFieldId = data['field_configuration_id'];
                this.confFields = data['fieldsList'];
                //Remove Resolution out of result
                var index = this.confFields.findIndex(elm => elm['field_name'] == 'Resolution');
                this.confFields.splice(index, 1);
            })
        }
    }

    getIssueLinks() {
        this.issueService.getIssueLink()
        .subscribe ((links)=>{
            //console.log(links);
            this.issueLinks = links;
        })
    }

    getResolutions() {
        this.issueService.getResolutions()
        .subscribe( (resolutions)=>{
            //console.log(data);
            this.resolutions = resolutions;
        })
    }

    getPriorities() {
        this.issueService.getPriorities()
        .subscribe( (priorities)=>{
            this.priorities = priorities;
        })
    }
    
    onSelectUser(event: TypeaheadMatch): void {
        //this.user = event.item.id;
        this.user = event.item;
    }

    onChange(event) {
        const keyword = event;
        var keywordAndExceptUsers = { keyword: keyword, user_id: [] };
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.userService.getUsersByKeywordAndExcept(keywordAndExceptUsers).subscribe((users: User[]) => {
                this.reporters = users;
            });
        }, 250);
    }

    selectProject(projectId:any) {
        this.selectedProjectId = projectId;
        this.getIssueTypesByProjectId(projectId);
        // this.getProjectLead(projectId);
    }

    getProjectLead(id) {
        // Get project lead
        this.projectService.getProjectById(id)
           .subscribe(response => {
                this.projectLeadId = response['project']['project_lead'];  
                this.defaultAssigneeId = this.projectLeadId;
                this.frmTicket.controls['assignee_id'].setValue(this.defaultAssigneeId);  
            });
    }

    showFields() {
        $('.btn-trigger-create').on('click', function() {
            $('.pop-conf-create-ticket').css({'display':'block'});
        });
        
        let popup = $('.pop-conf-create-ticket');
        this.togglePopup(popup);
    }

    togglePopup(popup) {
        $(document).mouseup(e => {
           if (!popup.is(e.target) // if the target of the click isn't the container...
           && popup.has(e.target).length === 0) // ... nor a descendant of the container
           {
             popup.css({'display':'none'});
          }
         });
    }

    // Procees attach file
    onSelect(event:any) {
        if (event && event.addedFiles) {
            this.files.push(...event.addedFiles);
        }
    }

    onRemove(event) {
        if (this.files) {
            this.files.splice(this.files.indexOf(event), 1);
        }
    }

    onRemoveIssueFromLink(event) {
        if (this.linkIssueIds) {
            this.linkIssueIds.splice(this.linkIssueIds.indexOf(event), 1);
        }
    }

    toggleFieldList(custom=false){
        this.isCustom = custom;
        this.toggleCheckboxes();
    }

    toggleCheckboxes() {
        let fieldPopup = $('.pop-conf-create-ticket').find('.selectable');

        if (!this.isCustom) {
            $(fieldPopup).find('input[type="checkbox"]').attr('disabled', true);
        } else {
            $(fieldPopup).find('input[type="checkbox"]').attr('disabled', false);
        }
        //$('.pop-conf-create-ticket').find('.unconfigurable').find('input[type="checkbox"]').attr('disabled', true);
    }

    showConfField(event:any) {
        let data_id = $(event.target).attr('id');
        let checkbox = $(event.target);
        let params = {
            project_id: this.selectedProjectId,
            issue_type_id: this.selectedIssueTypeId,
            field_configuration_id: this.confFieldId,
            visible: $(checkbox).is(':checked') ? 1 : 0,
            field_id: data_id,
        };

        this.issueService.configField(params)
        .subscribe(response=>{
                this.getConfFieldList();
            },
            error=>{
                console.log(error);
            }
        )
    }

    onChangeAssignee(assignee_id:any) {
        this.assignee_id = assignee_id;
        this.frmTicket.controls['assignee_id'].setValue(assignee_id);
        this.defaultAssigneeId = assignee_id;
    }

    selectResolution(resolution_id) {
        this.resolution_id = resolution_id;
    }

    setPriority(priority_id) {
        this.priority_id = priority_id;
    }

    getElementById(arr, id) {
        return arr.filter(elm=>elm.id == id);
    }

    getLink(linkId) {
        this.linkTypes = this.getElementById(this.issueLinks, linkId);
    }

    selectIssueToLink(issue_id) {
        let arr = [];
        if (!this.linkIssueIds.includes(issue_id)) {
            this.linkIssueIds.push(issue_id);
        }
        //this.linkIssueIds = arr;
    }

    filterItemById(item, id) {
        return item.filter(elm=>elm.id == id);
    }

    resetForm() {
        this.selectedProjectId = null;
        this.selectedIssueTypeId = null;
        this.frmTicket.reset();
        this.submitted = false;
    }

    onChangeRequestType(id) {
        // Get default assignee for the ticket
        let params = {
            request_type_id: id
        }
        
        if (this.form.request_type_id.value != null) {
            $('.validate-request-type').removeClass('no-request-type');
        }
        
        this.issueService.getDefaultAssignee(params)
            .subscribe(response => {
                    let assignee_id = response['primaryMemberId'];
                    this.defaultAssigneeId = assignee_id;
                    this.frmTicket.controls['assignee_id'].setValue(assignee_id);
                }, error => {
                    console.log(error);
                }
            );
    }

    // Send request to parent component
    refreshIssue() {
        this.refresh.emit();
    }
    
    createTicket() {
        this.alertService.clear();
        this.submitted = true;
        
        if (this.frmTicket.invalid) {
            if (this.form.request_type_id.value == null) {
                $('.validate-request-type').addClass('no-request-type');
            }
            return;
        }

        //var due_date  = Date.parse(this.form.due_date.value.split('-').reverse().join('-'))/1000;
        let reporter_id = (this.user && this.user.id) ? this.user.id : this.curUser.id;
        let issue_link_id = (this.linkTypes && this.linkTypes[0]) ? this.linkTypes[0]['id'] : null;
        let issue_link_name = (this.linkTypes && this.linkTypes[0]) ? this.linkTypes[0]['outward_desc'] : null;
        let issue_link = [
            {
                issue_link_id: issue_link_id,
                issue_link_name: issue_link_name,
                to_issue_ids: this.linkIssueIds
            }
        ];

        let project_id = this.form.project_id.value;
        let project_key = this.filterItemById(this.projects, project_id)[0]['key'];

        let data = new FormData();
        data.append('project_id', this.form.project_id.value);
        data.append('issue_type_id', this.form.issue_type_id.value);
        data.append('request_type_id', this.form.request_type_id.value);
        data.append('subject', this.form.subject.value);
        data.append('reporter_id', reporter_id);
        data.append('current_user_id', this.curUser.id);
        data.append('assignee_id', this.defaultAssigneeId);

        if (this.files) {
            //data.append('attached_file', JSON.stringify(this.files));
            for (var i = 0; i<this.files.length;i++) {
                data.append('attached_file[]', this.files[i]);
            }
        }

        //data.append('due_date',due_date.toString());
        data.append('description', this.form.description.value);
        data.append('priority_id', this.priority_id);
        data.append('resolution_id', this.resolution_id);
        data.append('issue_link', JSON.stringify(issue_link));

        //todo : send to api to request create a new ticket        
        this.loading = true;
        this.issueService.create(data)
        .subscribe(response => {
                this.alertService.success(response['message'], true);
                //this.refreshIssue();
                this.hidePopup();
                this.resetForm();
                this.loading = false;
            },
            error=>{
                console.log(error);
                this.loading = true;
            }
        )
    }
}
