import { Component, OnInit } from '@angular/core';
import { Alert, AlertType } from '../../models/Alert';
import { AlertService } from '../../services/alert.service';
import * as $ from 'jquery';

@Component({
    selector: 'app-alert',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss']
})
 
export class AlertComponent implements OnInit {
    alerts: Alert[] = [];
 
    constructor(private alertService: AlertService) { }
 
    ngOnInit() {
        this.alertService.getAlert().subscribe((alert: Alert) => {
            if (!alert) {
                // Clear alerts when an empty alert is received
                this.alerts = [];
                return;
            }
 
            // Add alert to array
			this.alerts.push(alert);
			setTimeout(() => {  
                this.alerts = this.alerts.filter(x => x !== alert);  
            }, 2000); 
		});
		
		$('body').on('click', function() {
			$('#alert-container').html('');			
		});
    }
 
    removeAlert(alert: Alert) {
        this.alerts = this.alerts.filter(x => x !== alert);
    }
 
    cssClass(alert: Alert) {
        if (!alert) {
            return;
        }

        // Return css class based on alert type
        switch (alert.type) {
            case AlertType.Success:
                return 'alert alert-success';
            case AlertType.Error:
                return 'alert alert-danger';
            case AlertType.Info:
                return 'alert alert-info';
            case AlertType.Warning:
                return 'alert alert-warning';
        }
    }
}