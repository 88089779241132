import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../utils/constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class IssueService {

    constructor(private http: HttpClient,
        private constants: Constants) { }

    // Get issue type schemas with pagination
    getIssuesWithPagination(params) {
        return this.http.post(this.constants.URL_API + '/filter-issues', params)
        .pipe(map((response) => {
            return response['data']['issuesList'];
            },
            error=>{
                console.log(error);
            })
        );
    }

    getIssues() {
        return this.http.get(this.constants.URL_API + '/issues')
        .pipe(map((response) => {
            return response['data']['issuesList'];
            },error=>{
                console.log(error);
            })
        );
    }

    // Delete an issue
    deleteIssue(id) {
        return this.http.delete(this.constants.URL_API + '/issues/' + id)
            .pipe(map(response => {
                return response;
            })
        );
    }

    getPriorities() {
        return this.http.get(this.constants.URL_API +'/priorities')
        .pipe(map((response) => { 
                return response['data']['prioritiesList'];
            },
            error=>{
                console.log(error);
            })
        );
    }

    // Get Priority by ID
    getPriorityById(id: number) {
        return this.http.get(this.constants.URL_API + '/priorities/' + id)
        .pipe(map((response) => {
                return response['data'];
            },error => {
                console.log(error);
            })
        );
    }

    getResolutions() {
        return this.http.get(this.constants.URL_API +'/resolutions')
        .pipe( map( (response) => {            
                return response['data']['resolutionsList'];
            },
            error => {
                console.log(error);
            })
        );
    }

    // Get Resolution
    getResolutionById(id: number) {
        return this.http.get(this.constants.URL_API + '/resolutions/' + id)
        .pipe(map((response) => {
                return response['data'];
            },error => {
                console.log(error);
            })
        );
    }

    // Get default assignee of the ticket create
    getDefaultAssignee(params) {
        return this.http.post(this.constants.URL_API + '/issue/get-default-assignee', params)
        .pipe(map((response) => {
                return response['data'];
            },
            error => {
                console.log(error);
            })
        );
    }

    // Get an issue detailed information
    getIssue(id:any) {
        return this.http.get(this.constants.URL_API + '/issues/' +id)
        .pipe(map((response) => {
                return response['data'];
            },
            error => {
                console.log(error);
            })
        );
    }

    // Get statuses of issue
    getStatuses() {
        return this.http.get(this.constants.URL_API + '/issue-statuses')
        .pipe( map((response)=>{
                return response['data']['issueStatuses'];
            } )
        )
    }

    // Get statuses of issue
    getRequestTypes() {
        return this.http.get(this.constants.URL_API + '/request-types')
        .pipe( map((response) => {
            return response['data']['requestTypesList'];
            })
        );
    }

    // Get list of request types by User
    getRequestTypesByUser(params) {
        return this.http.post(this.constants.URL_API + '/list-request-types-by-user-issues', params)
        .pipe( map((response) => {
            return response['data']['requestTypesList'];
            })
        );
    }

    // Get list of statuses by User
    getCommonStatuses() {
        return this.http.get(this.constants.URL_API + '/list-common-statuses')
        .pipe( map((response) => {
            return response['data'];
            })
        );
    }
    
    // Get list of statuses by User
    getStatusesByUser(params) {
        return this.http.post(this.constants.URL_API + '/list-statuses-by-user-issues', params)
        .pipe( map((response) => {
            return response['data']['statusesList'];
            })
        );
    }

    // Get Status object
    getStatusById(id: number) {
        return this.http.get(this.constants.URL_API + '/issue-statuses/' + id)
        .pipe(map((response) => {
                return response['data'];
            },error=>{
                console.log(error);
            })
        );
    }

    // Get list of comments
    getComments(params) {
        return this.http.post(this.constants.URL_API +'/issue-comments/list', params)
        .pipe ( map((response)=>{
                return response['data']['listOfComments'];
            },
            error=>{
                console.log(error);
            })
        )
    }

    addComment(params) {
        return this.http.post(this.constants.URL_API +'/issue-comments', params);
    }

    updateComment(params) {
        return this.http.post(this.constants.URL_API +'/issue-comments/update', params);
    }

    deleteComment(params) {
        return this.http.post(this.constants.URL_API +'/issue-comments/delete', params);
    }

    //get all actions on a ticket: comment, change status, activity, history
    getAllActions(params) {
        return this.http.post(this.constants.URL_API + '/issue-comments/all', params)
        .pipe ( map((response) => {
                return response['data'];
            },
            error => {
                console.log(error)
            })
        )
    }

    history(params) {
        return this.http.post(this.constants.URL_API +'/issue-comments/history', params)
        .pipe ( map((response)=>{
                return response['data'];
            },
            error=>{
                console.log(error)
            })
        )
    }

    activity(params) {
        return this.http.post(this.constants.URL_API +'/issue-comments/activity', params)
        .pipe ( map((response)=>{
                return response['data'];
            },
            error=>{
                console.log(error)
            })
        )
    }

    getWorkflow(workflow_id) {
        return this.http.get(this.constants.URL_API +'/workflows/'+workflow_id)
        .pipe ( map ((response)=>{
                return response['data']['workflowDetail'];
            },
            error=> {
                console.log(error);
            })
        );
    }

    //get transitions to show popup
    getTransitionFlow(params) {
        return this.http.post(this.constants.URL_API +'/issue/get-fields-from-issue-status', params)
        .pipe ( map ((response)=>{
                return response['data']['fieldsList'];
            },
            error=> {
                console.log(error);
            })
        );
    }

    //config field
    configField(params) {
        return this.http.post(this.constants.URL_API +'/issue/configure-field-on-screen',params);
    }

    //get config fields
    getConfFields(params) {
        return this.http.post(this.constants.URL_API +'/issue/list-of-fields-from-screen', params)
        .pipe ( map((response)=>{
                return response['data'];
            },
            error=>{
                console.log(error);
            })
        )
    }

    //user portal
    getFieldByCategory(params) {
        return this.http.post(this.constants.URL_API +'/list-request-type-fields', params)
        .pipe ( map((response)=>{
                return response['data'];
            },
            error=>{
                console.log(error);
            }
            )
        )
    }
    getIssueLink() {
        return this.http.get(this.constants.URL_API +'/issue-linkings')
        .pipe( map( (response)=>{
                return response['data']['issueLinkingsList'];
            },
            error=>{
                console.log(error);
            })
        );
    }

    //list the watchers who are viewing the issue
    listCurWatchers(params) {
        return this.http.post(this.constants.URL_API + '/issue/list-of-watchers-viewing', params)
        .pipe( map((response)=>{
                return response['data']['listOfWatchers'];
            },
            error=>{
                console.log(error);
            })
        );
    }

    listNonWatchers(params){
        return this.http.post(this.constants.URL_API +'/issue/list-of-watchers-not-viewing', params)
        .pipe( map((response)=>{
                return response['data']['listOfWatchers'];
            },
            error=>{
                console.log(error);
            })
        );
    }

    toggleWatchIssue(params) {
        return this.http.post(this.constants.URL_API + '/issue/proceed-watching-issue', params);
    }

    //add/remove watcher from an issue
    updateWatcher(params) {
        return this.http.post(this.constants.URL_API + '/issue/update-watcher', params);
    }

    //create helpdesk ticket
    create(params) {
        return this.http.post(this.constants.URL_API + '/issues', params);
    }

    updateInline(params) {
        return this.http.post(this.constants.URL_API + '/issue/update-inline', params);
    }

    updateStatus(params) {
        return this.http.post(this.constants.URL_API +'/issue/update-status',params);
    }

    // Remove Linked Issue
    removeLinkedIssue(params) {
        return this.http.post(this.constants.URL_API + '/issue/remove-linked-issue', params)
        .pipe(map((response) => {
                return response;
            },error => {
                console.log(error);
            })
        );
    }

    getTotalRequestOfUser(param) {
        return this.http.post(this.constants.URL_API + '/total-request-of-user', param);
    }
}
