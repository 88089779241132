import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Constants } from '../utils/constants';
import { CustomField } from '../models';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomFieldService {

	constructor(private http: HttpClient,
				private constants: Constants) { }

	// Get list of custom fields
	getCustomFields() {
		return this.http.get(this.constants.URL_API + '/custom-fields')
			.pipe(map((response) => {
				return response['data'];
			},error=>{
				console.log(error);
			})
		);
	}

	// Get list of custom fields based on pagination
	filterCustomFields(params) {
		return this.http.post(this.constants.URL_API + '/filter-custom-fields', params)
			.pipe(map((response) => {
				return response['data'];
			},error=>{
				console.log(error);
			})
		);
	}

	// Get list of custom field types
	getCustomFieldTypes() {
		return this.http.get(this.constants.URL_API + '/custom-field-types')
			.pipe(map((response) => {
				return response['data'];
			},error=>{
				console.log(error);
			})
		);
	}

	getCustomField(id) {
		return this.http.get(this.constants.URL_API + '/custom-fields/' + id)
			.pipe(map((response) => {
				return response['data']['customField'];
			},error=>{
				console.log(error);
			})
		);
	}
}
