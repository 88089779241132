import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../utils/constants';
import { Project } from '../models/Project';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ProjectService {
    projects: Project[];
    constructor(private http: HttpClient, private constants: Constants) {}

    // Get list all of projects
    getProjectList(): Observable<Project[]> {
        return this.http.get(`${this.constants.URL_API}/projects`).pipe(
            map(response => {
                this.projects = response['data']['projects'];
                return this.projects;
            })
        );
    }

    // Get latest project created
    getLatestProjectCreated(): Observable<Project[]> {
        return this.http.get(`${this.constants.URL_API}/project/latest-project`).pipe(
            map(response => {
                this.projects = response['data']['project'];
                return this.projects;
            })
        );
    }

    // Get list of project based on pagination
    getProjectFiltersList(params): Observable<Project[]> {
        return this.http
            .post(`${this.constants.URL_API}/filter-projects`, params)
            .pipe(
                map(response => {
                    this.projects = response['projects'];
                    return this.projects;
                })
            );
    }

    // Get project details information
    getProjectById(id: number) {
        return this.http.get(this.constants.URL_API + '/projects/' + id).pipe(
            map(response => {
                this.projects = response['data'];
                return this.projects;
            })
        );
    }

    // Create a new project
    create(project) {
        return this.http.post(this.constants.URL_API + '/projects', project);
    }

    // Update project
    update(project) {
        return this.http.post(this.constants.URL_API + '/project/update', project);
    }

    // Delete a project
    deleteProject(id) {
        return this.http.delete(this.constants.URL_API + '/projects/' + id)
            .pipe(map(response => {
                return response;
            }
        ));
    }

    // Get all users by role of project
    getUserByRole(projectId, roleId) {
        return this.http
            .post(`${this.constants.URL_API}/project/filter-users`, {
                keyword: '',
                project_id: projectId,
                role_id: roleId
            })
            .pipe(
                map(response => {
                    return response['data'];
                })
            );
    }

    // Add users to the role of project
    addUsersToRole(params) {
        return this.http.post(this.constants.URL_API + '/project/add-users-to-role', params)
            .pipe(
                map(response => {
                    return response;
                })
            );
    }

    // Get default assignee by project id
    getDefaultAssignee(project_id) {
        return this.http
            .post(`${this.constants.URL_API}/project/get-default-assignee`, {
                project_id: project_id
            })
            .pipe(
                map(response => {
                    return response['data'][0];
                })
            );
    }

    // Update default assignee
    updateDefaultAssignee(params) {
        return this.http.post(this.constants.URL_API + '/project/update-default-assignee', params)
            .pipe(
                map(response => {
                    return response;
                })
            );
    }

    // Update default assignee
    removeUserOutOfRole(project_id, user_id, role_id) {
        return this.http.post(this.constants.URL_API + '/project/remove-users-out-role', {
            project_id: project_id,
            user_id: user_id,
            role_id: role_id
        });
    }

    // Auto generate key from a project name, as a suggest for user
    // User can manually input a key
    generateKey(project_name) {
        let arr_letter = [];
        let suggest_key = '';
        if (/\s/.test(project_name)) {
            //console.log('contains whitespace');
            let arr_str = project_name.split(' ');
            for (var i = 0 ; i < arr_str.length && i < 10; i++) {
                arr_letter.push(arr_str[i].charAt(0).toUpperCase());
            }
            suggest_key = arr_letter.join('');
            return suggest_key;
        } else {
            //console.log('not contain whitespace');
            var spec_format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
            var countWord = project_name.trim().split(spec_format).length;
            if (countWord == 1) {
                if (project_name.indexOf('-') !=-1) {
                    let arr_str = project_name.split('-');
                    for (var i = 0 ; i < arr_str.length && i < 10; i++) {
                        arr_letter.push(arr_str[i].charAt(0).toUpperCase());
                    }
                    suggest_key = arr_letter.join('');
                    return suggest_key;
                }
                suggest_key = project_name.substring(0,4).toUpperCase();
                return suggest_key;
            } else {
                var number_format = /[0-9]/g;
                let new_string = project_name;//remove number
                while (number_format.test(new_string) ) {
                    new_string = new_string.replace(/[0-9]/g, '');
                }
                let arr_str = new_string.split(spec_format);
                for (var i=0; i < arr_str.length && i < 10;i++) {
                    suggest_key += arr_str[i].substring(0,arr_str[i].length-1).toUpperCase();
                }
                suggest_key = suggest_key.substring(0,10);
               // let new_string  = project_name;
                /*while (spec_format.test(new_string)) {
                    new_string = new_string.replace(spec_format,'');//remove special character
                }
                //console.log(new_string);
                suggest_key = new_string.substring(0,3).toUpperCase();*/
                return suggest_key;
            }
        }
        return suggest_key;
    }
}
