import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Constants } from '../../utils/constants';
import { Title } from '@angular/platform-browser';
import { AlertService, AuthenticationService } from '../../services';
import { User } from '../../models';
import { UserIdleService } from 'angular-user-idle';

@Component({
    selector: 'app-auth',
    templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    adminReturnUrl: string;
    loginError: string;
    userReturnUrl: string;
    currentUser =  <User>{};
    clicked : boolean;
    constructor(
        private titleService: Title,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private userIdle: UserIdleService,
        private constants: Constants
    ) {
        this.titleService.setTitle('Log in - HELP DESK SYSTEM');

        // Redirect to dashboard or user portal if already logged in
        this.currentUser = this.authenticationService.currentUserValue;
        localStorage.setItem('latestSidebar', 'user-portal');
        if (this.currentUser != null && this.currentUser.group_role.length == 0) {
            this.router.navigateByUrl('/user/portal');
        } else {
            this.router.navigateByUrl('/user/portal');
        }
    }

    stop() {
        this.userIdle.stopTimer();
    }

    stopWatching() {
        this.userIdle.stopWatching();
    }

    startWatching() {
        this.userIdle.startWatching();
    }

    restart() {
        this.userIdle.resetTimer();
    }

    ngOnInit() {
        this.authenticationService.logout();
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
        this.authenticationService.isReLogin.subscribe(clicked => this.clicked = clicked);

        // Get return url from route parameters or default to '/'
        this.adminReturnUrl = this.route.snapshot.queryParams['returnUrl'] || '/user/portal';
        this.userReturnUrl = '/user/portal';

        this.startWatching();

        // Start watching when user idle is starting.
        this.userIdle.onTimerStart().subscribe(/*count => console.log(count)*/);

        // Start watch when time is up.
        this.userIdle.onTimeout().subscribe(() => {            
            this.authenticationService.logout();
            this.requireLogin();
            if (this.clicked) this.stopWatching();
        });
    }

    requireLogin () {
        let timeoutBtn = document.getElementById("popup_timeout");
        if (timeoutBtn) {
            timeoutBtn.click();
        }
        this.authenticationService.isReLogin.subscribe(clicked => this.clicked = clicked);
    }
    
    // Convenience getter for easy access to form fields
    get form() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        // Stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }
        
        this.loading = true;
        this.authenticationService.login(this.form.username.value, this.form.password.value)
            .pipe(first())
            .subscribe(
                data => {
                    if (this.authenticationService.isLoggedIn) {
                        this.currentUser = this.authenticationService.currentUserValue;

                        let returnUrl = '';
                        if (this.currentUser.group_role && this.currentUser.group_role.length == 0) {
                            returnUrl = this.userReturnUrl;
                        } else {
                            returnUrl = this.adminReturnUrl;
                        }
                        this.authenticationService.updateLoginStatus(true);
                        this.restart();
                        this.router.navigate([returnUrl]);
                    } else {
                        this.alertService.error(data['message'], true);
                    }
                },
                error => {
                    this.authenticationService.updateLoginStatus(false);                    
                    this.alertService.error(error, true);
                    this.loading = false;
                });
    }

}
