export class Constants {
	
	// Enpoint URL API
	//public URL_API: string = 'http://localhost:8000/api/v1';
	//public URL_API: string = 'http://192.168.21.14:7000/api/v1';
	// public URL_API: string = 'http://192.168.20.174:7100/api/v1';

	// Client
	//public URL_API: string = 'https://helpdesk-api.acgovpb.org/api/v1';
	public URL_API: string = 'https://api.helpdesk.acgovpb.org/api/v1';

	static readonly DATE_FMT = 'MM/ dd/ yyyy';
	static readonly DATE_TIME_FMT = `${Constants.DATE_FMT} hh:mm:ss`;
	static readonly DEFAULT_SESSION_LOGIN_TIMEOUT = 1800; //30 minutes to check the timeout
	static readonly DEFAULT_TIMEZONE = 'PST';
	public LOGO_PATH = 'assets/themes/itt/img/logo';

	public DEFAULT_PROJECT_AVATAR = 'assets/themes/itt/img/avatars/projects/project-default.png';
	public DEFAULT_USER_AVATAR = 'assets/themes/itt/img/avatars/users/no-image.gif';
	public DEFAULT_REQUEST_TYPE_AVATAR = '/assets/themes/itt/img/avatars/request-types/request_type_default.png';
	public DEFAULT_ISSUE_TYPE_AVATAR = '/assets/themes/itt/img/avatars/issue-types/issue_type_default.png';
	public DEFAULT_CUSTOM_FIELD_TYPE_AVATAR = '/assets/themes/itt/img/avatars/custom-field-types/custom_field_type_default.png';
	public DEFAULT_PRIORITY_AVATAR = '/assets/themes/itt/img/avatars/priorities/priority_default.png';
	public LOADING_AVATAR = '/assets/themes/itt/img/loading.gif';

	public PROJECT_IMAGE_FOLDER = '/public/images/projects/';
    public USER_IMAGE_FOLDER = '/public/images/users/';
    public ISSUE_FILE_FOLDER = '/public/issues/';
	public REQUEST_TYPE_IMAGE_FOLDER = '/public/images/request-types/';
	public ISSUE_TYPE_IMAGE_FOLDER = '/public/images/issue-types/';
	public CUSTOM_FIELD_TYPE_IMAGE_FOLDER = '/public/images/custom-field-types/';
	public PRIORITY_IMAGE_FOLDER = '/public/images/priorities/';
	public ISSUE_FOLDER = '/public/issues/';
	public DEFAULT_ITEMS_PER_PAGE = 10;
	public DEFAULT_QUICK_STAR_GUIDE = '/assets/files/Probation_Help_Desk.PDF';
}
