import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import * as JWT from 'jwt-decode';

import { User } from '../models';
import { Constants } from '../utils/constants';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    redirectUrl: string;
    errorData: {};
    
    private clickLoginPopup = new BehaviorSubject<boolean>(false);
    isReLogin = this.clickLoginPopup.asObservable();

    constructor(private http: HttpClient,
        private constants: Constants) {

        // Retrieve user if saved in local storage
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    updateLoginStatus(click:boolean) {
        this.clickLoginPopup.next(click);
    }
    
    public get currentUserValue() {
        return this.currentUserSubject.value;
    }

    // User login
    login(username: string, password: string) {
        let requestURL = this.constants.URL_API + '/login';
        return this.http.post<any>(requestURL, { username, password })
            .pipe(map(userData => {

                // Login successful if there's a jwt token in the response
                // if (user && user.token) {
                if (userData) {
                    let user = userData['data']['user'];
                    // Store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    localStorage.setItem('token', JSON.stringify(userData['data']['access_token']));
                    this.currentUserSubject.next(user);
                    this.updateLoginStatus(true);
                    return user;
                }
            // }), catchError(this.handleError)
            })
        );  
    }

    autologin(token) {
        let requestURL = this.constants.URL_API + '/autologin' + '/' + token;
        return this.http.post<any>(requestURL, {})
            .pipe(map(userData => {

                // Login successful if there's a jwt token in the response
                // if (user && user.token) {
                if (userData) {
                    let user = userData['data']['user'];
                    // Store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    localStorage.setItem('token', JSON.stringify(userData['data']['access_token']));
                    this.currentUserSubject.next(user);
                    return user;
                }
            })
        );  
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error('An error occurred:', error.error.message);

        } else {
            // The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong.
			//console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
        }

        // Return an observable with a user-facing error message
        this.errorData = {
			errorTitle: 'Oops! Request for document failed',
			errorDesc: 'Something bad happened. Please try again later.'
		};

        return throwError(this.errorData);
    }

    isLoggedIn() {
        if (localStorage.getItem('currentUser')) {
          	return true;
		}

        return false;
    }

    getToken() {
        const userToken = JSON.parse(localStorage.getItem('token'));
        return userToken;
    }

    getTokenExpirationDate(token: string): Date {
        let decoded: any = {};
        decoded = JWT(token);
        if (decoded.exp === undefined) return null;

        const date = new Date(0);
        date.setUTCSeconds(decoded.exp);
        return date;
    }

    isTokenExpired(token?: string): boolean {
        if(!token) token = this.getToken();
        if(!token) return true;

        const date = this.getTokenExpirationDate(token);

        if (date === undefined) return false;
        return !(date.getTime() > new Date().getTime());
    }

    logout() {
        // Remove all from local storage when user log-out
		localStorage.clear();
        this.updateLoginStatus(false);
        this.currentUserSubject.next(null);
    }

    // Check token is valid
    checkToken(params) {
        return this.http.post(this.constants.URL_API + '/check-token', params)
                .pipe(map (response => {
                    return response;
                }, error => {
                    console.log(error);
                }
            )
        );
    } 

}
