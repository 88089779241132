import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
	selector: 'app-404',
  	templateUrl: '404.component.html'
})
export class P404Component {

  	constructor(private titleService: Title) {
		this.titleService.setTitle('404 Error - HELP DESK SYSTEM');
	}
}
